import { type FC, lazy, Suspense, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { useQuery } from '@apollo/client';
import { Center } from '@chakra-ui/react';
import getLoggedInRoutesAccess from 'Main/SecuredRoutes/getLoggedInRoutesAccess';
import useLogoutClosedUserAccount from 'Main/SecuredRoutes/hooks/useLogoutClosedUserAccount';

import {
  CompanyInvitationRole,
  type CompanyUserFeature,
  CompanyUserKycStatus,
  CompanyUserStatus,
  EntityType,
  FeatureName,
  Role,
} from '__generated__/GQL';
import { useEnrollTrustedDevice } from 'common/hooks/strongAuthentication/useEnrollTrustedDevice';
import { getCanAccessFeature } from 'common/hooks/useCanCompanyUserAccessFeature';
import useCompanyContext from 'common/hooks/useCompanyContext';
import { CurrentPricingPlanProvider } from 'common/hooks/useCurrentPricingPlan';
import { FeaturesContext } from 'common/hooks/useFeaturesContext';
import useIsLegalRegistrationNumberRequired from 'common/hooks/useIsLegalRegistrationNumberRequired';
import { webAppOpeningTransaction } from 'common/sentry/webAppOpeningTransaction';
import SunshineLoader from 'components/_core/SunshineLoader';
import ErrorBoundary from 'components/ErrorBoundary';
import ErrorView from 'components/ErrorView';
import Login from 'components/Login';
import Logout from 'components/Logout';
import ActionRequests from 'features/ActionRequests';
import AgreementCatchup from 'features/AgreementCatchup';
import { INTERCOM_LAUNCHER_CLASS_NAME } from 'features/Intercom';
import Authorize from 'features/OAuth';
import AccountPendingMessage from 'features/OAuth/AccountPendingMessage';
import { loadOnboardingChunk } from 'features/Onboarding';
import { PricingPlanMigrationProvider } from 'features/PricingPlanMigration';
import { fullPageSubscriptionManagementRoutes } from 'features/SubscriptionManagement';
import TaxResidencyUpdate from 'features/TaxResidencyUpdate';
import TeamOnboarding from 'features/TeamOnboarding/Onboarding';
import TeamOnboardingTutorials from 'features/TeamOnboarding/TeamOnboardingTutorials';
import PlanChoicePage from 'features/Upgrade/pages/PlanChoice';
import { useUser } from 'helpers/auth';

import useFeatureFlagsSwitcher from '../../common/hooks/useFeatureFlagsSwitcher';
import useQueryParams from '../../common/hooks/useQueryParams';
import { computeCompanyList } from '../../common/hooks/useUserCompanyList';
import { FullscreenContainer } from '../styles';
import AccountSelection from './AccountSelection';
import AuthenticationDeviceRequests from './AuthenticationDeviceRequests';
import BeneficiaryNoAccountAccess from './BeneficiaryNoAccountAccess';
import CardPaymentStrongAuthenticationRequests from './CardPaymentStrongAuthenticationRequests';
import FraudWarningModal from './FraudWarningModal';
import { RootDocument } from './graphql/root.gql';
import { RootCompanyDocument } from './graphql/rootCompany.gql';
import useFraudWarningModal from './hooks/useFraudWarningModal';
import { TeamOnboardingTutorialsCardDocument } from './hooks/useHasTeamOnboardingTutorialsCard/teamOnboardingTutorialsCardDocument.gql';
import InvitationRedemptionError from './InvitationRedemptionError';
import UserLocked from './UserLocked';
import { UserLockedReasons } from './UserLocked/types';
import { loadWithinAppLayoutRoutesChunk } from './WithinAppLayoutRoutes';

const WithinAppLayoutRoutes = lazy(() => {
  const span = webAppOpeningTransaction.addSpan({
    name: 'Load the Customer chunk',
    op: 'bundle',
  });

  return loadWithinAppLayoutRoutesChunk().finally(() => {
    span?.finish();
  });
});

const Onboarding = lazy(async () => {
  const span = webAppOpeningTransaction.addSpan({
    name: 'Load the Onboarding chunk',
    op: 'bundle',
  });

  return loadOnboardingChunk().finally(() => {
    span?.finish();
  });
});

export type SecuredRoutesState =
  | {
      invitationRedeemedByExistingTeamMember?: boolean;
    }
  | undefined;

const LoggedInRoutes: FC = () => {
  const companyContext = useCompanyContext();
  const queryParams = useQueryParams();
  const addNewAccount = Boolean(queryParams.get('addNewAccount'));

  const { setIsBetaTester } = useFeatureFlagsSwitcher();

  useLogoutClosedUserAccount();

  const user = useUser();
  const intercom = useIntercom();

  const canUseEnrollTrustedDevice = useEnrollTrustedDevice();

  const { companyProfileId } = companyContext;

  const rootQuery = useQuery(RootDocument, {
    onCompleted(data) {
      const accessibleCompanies = data.viewer.companyUsers.filter(
        (companyUser) => companyUser.status === CompanyUserStatus.Active,
      );

      /**
       * If there's only one accessible company, set it in company context.
       * Else if there's already a selected company, it will be kept.
       * Else, the user will be prompted to select a company in AccountSelection.
       */
      if (accessibleCompanies.length === 1) {
        const accessibleCompany = accessibleCompanies[0]!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

        companyContext.setCompanyContext({
          companyProfileId: accessibleCompany.companyProfileId,
          companyUserId: accessibleCompany.companyUserId,
        });
      }
    },
  });

  const rootCompanyQuery = useQuery(RootCompanyDocument, {
    onError: () => companyContext.clearCompanyContext(),
    skip: !companyProfileId,
    variables: { companyProfileId: companyProfileId as string },
  });

  const {
    companyUserId,
    hasInvitationRole,
    invitationRole,
    isAccountLocked,
    isAccountValidated,
    isBeneficiaryWithNoAccess,
    isBetaTester,
    isNewOnboardingAllowed,
    isOnboarded,
    kycStatus,
    shouldAskForEpCguAgreement,
  } = getLoggedInRoutesAccess(rootCompanyQuery.data);

  const { isRequired } = useIsLegalRegistrationNumberRequired(
    rootCompanyQuery.data,
  );

  const { companies, isLockedFromEveryCompany } = computeCompanyList(
    rootQuery.data,
    {
      excludeLockedAccess: false,
    },
  );

  const { dismissFraudWarning, shouldDisplayFraudWarning } =
    useFraudWarningModal();

  const teamOnboardingTutorialCardsQuery = useQuery(
    TeamOnboardingTutorialsCardDocument,
    {
      skip:
        !rootCompanyQuery.data ||
        rootCompanyQuery.data.viewer.companyUser.roles.every(
          (role) => role !== Role.Accountant && role !== Role.Employee,
        ),
      variables: {
        filters: {
          entityId: companyUserId,
          entityType: EntityType.CompanyUser,
        },
      },
    },
  );

  const tutorialCards = {
    cardId:
      teamOnboardingTutorialCardsQuery.data?.viewer.cards.edges[0]?.node
        ?.cardId,
    hasTeamOnboardingCards:
      !!teamOnboardingTutorialCardsQuery.data?.viewer.cards.edges.length,
  };

  useEffect(() => {
    intercom.boot({
      customLauncherSelector: `.${INTERCOM_LAUNCHER_CLASS_NAME}`,
      hideDefaultLauncher: true,
      userHash: user.userHash,
      userId: user.uid,
    });

    return () => {
      intercom.shutdown();
    };
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (isBetaTester) {
      setIsBetaTester(isBetaTester);
    }
  }, [setIsBetaTester, isBetaTester]);

  const error = rootQuery.error || rootCompanyQuery.error;

  if (error) {
    // Have any errors at this loading up stage caught by the top level ErrorBoundary and message
    throw new Error(`${error.name}: ${error.message}`);
  }

  /**
   * Keep in mind that it's possible for `rootCompanyQuery` to be skipped entirely (therefore rootCompanyQuery.loading === false).
   * In the context of a new user with no company yet, we render the entire tree below, and redirect them to `/onboarding` to create a new company.
   */
  if (rootQuery.loading || rootCompanyQuery.loading) {
    return (
      <FullscreenContainer>
        <SunshineLoader />
      </FullscreenContainer>
    );
  }

  if (hasInvitationRole && kycStatus === CompanyUserKycStatus.Refused) {
    return <UserLocked reason={UserLockedReasons.KycRefused} />;
  }

  if (
    !companyContext.companyProfileId &&
    companies.length > 0 &&
    !isLockedFromEveryCompany &&
    !addNewAccount
  ) {
    return <AccountSelection companies={companies} />;
  }

  const renderTeamOnboardingRoutes =
    hasInvitationRole &&
    !isOnboarded &&
    invitationRole !== CompanyInvitationRole.LegalBeneficiary;

  if (isAccountValidated && shouldDisplayFraudWarning) {
    return <FraudWarningModal dismiss={dismissFraudWarning} />;
  }

  // The switch below based on isAccountValidated will either add the
  // 'WithinAppLayoutRoutes' as accessible to the user or will only provide
  // the 'Onboarding' routes. In this case, the Redirect to /onboarding will
  // be taken if the user is not currently on an /onboarding route.
  // Note that it is necessary to add these <Route>s individually to avoid
  // the use of a <Fragment> which breaks the Switch (the Route is no longer
  // a direct child of the <Switch>).

  return (
    <>
      <Suspense
        fallback={
          <Center height="full">
            <SunshineLoader />
          </Center>
        }
      >
        <CurrentPricingPlanProvider
          plan={rootCompanyQuery.data?.viewer.company.currentPlan || null}
        >
          <FeaturesContext.Provider
            value={{
              features: (rootCompanyQuery.data?.viewer.companyUser.features ??
                []) as CompanyUserFeature[],
            }}
          >
            <PricingPlanMigrationProvider>
              <Switch>
                <Route
                  component={() => (
                    <UserLocked
                      companies={companies}
                      reason={UserLockedReasons.LockedByAdmin}
                    />
                  )}
                  path="/revoked"
                />
                <Route component={AgreementCatchup} path="/cgu" />
                <Route
                  component={TaxResidencyUpdate}
                  path="/tax-residency-update"
                />
                <Route component={PlanChoicePage} path="/upgrade/choice" />
                <Route
                  component={
                    isAccountValidated ? Authorize : AccountPendingMessage
                  }
                  path="/oauth2/authorize"
                />
                {isAccountValidated &&
                !tutorialCards.hasTeamOnboardingCards &&
                isBeneficiaryWithNoAccess ? (
                  <Route component={BeneficiaryNoAccountAccess} />
                ) : null}

                {getCanAccessFeature(
                  rootCompanyQuery.data?.viewer.companyUser.features as
                    | CompanyUserFeature[]
                    | undefined,
                )({ name: FeatureName.CompanyPlan })
                  ? fullPageSubscriptionManagementRoutes.map((route) => (
                      <Route key={route.path} {...route} />
                    ))
                  : null}

                {isAccountValidated &&
                !tutorialCards.hasTeamOnboardingCards &&
                !isBeneficiaryWithNoAccess ? (
                  <Route>
                    <WithinAppLayoutRoutes
                      isAccountLocked={!!isAccountLocked}
                      isLegalNumberRegistrationRequired={!!isRequired}
                      isNewOnboardingAllowed={!!isNewOnboardingAllowed}
                      shouldAskForEpCguAgreement={!!shouldAskForEpCguAgreement}
                    />
                  </Route>
                ) : null}

                {/* Condition below is duplicated because all children of a Switch should be Route or Redirect, not Fragment
        See https://stackoverflow.com/a/58169920/4361955 */}
                {isAccountValidated && tutorialCards.hasTeamOnboardingCards ? (
                  <Route path="/team/onboarding-tutorials">
                    <TeamOnboardingTutorials
                      cardId={tutorialCards.cardId as string}
                    />
                  </Route>
                ) : null}
                {isAccountValidated && tutorialCards.hasTeamOnboardingCards ? (
                  <Redirect to="/team/onboarding-tutorials" />
                ) : null}

                {/* Condition below is duplicated because all children of a Switch should be Route or Redirect, not Fragment
        See https://stackoverflow.com/a/58169920/4361955 */}
                {!isAccountValidated && renderTeamOnboardingRoutes ? (
                  <Route path="/team/onboarding">
                    <TeamOnboarding
                      companyProfileId={companyContext.companyProfileId}
                      role={invitationRole}
                    />
                  </Route>
                ) : null}
                {!isAccountValidated && renderTeamOnboardingRoutes ? (
                  <Redirect to="/team/onboarding" />
                ) : null}

                {/* Condition below is duplicated because all children of a Switch should be Route or Redirect, not Fragment
        See https://stackoverflow.com/a/58169920/4361955 */}
                {(!isAccountValidated && !renderTeamOnboardingRoutes) ||
                isLockedFromEveryCompany ? (
                  <Route component={Onboarding} path="/onboarding" />
                ) : null}
                {isLockedFromEveryCompany ? <Redirect to="/revoked" /> : null}
                {!isAccountValidated && !renderTeamOnboardingRoutes ? (
                  <Redirect to="/onboarding" />
                ) : null}
              </Switch>

              {isAccountValidated ? <ActionRequests /> : null}
            </PricingPlanMigrationProvider>
          </FeaturesContext.Provider>
        </CurrentPricingPlanProvider>

        <CardPaymentStrongAuthenticationRequests />
        {!canUseEnrollTrustedDevice ? <AuthenticationDeviceRequests /> : null}
      </Suspense>
    </>
  );
};

const SecuredRoutes: FC = () => (
  <Login>
    <Switch>
      <Route component={Logout} path="/logout" />
    </Switch>

    <ErrorBoundary errorView={ErrorView}>
      <LoggedInRoutes />

      <InvitationRedemptionError />
    </ErrorBoundary>
  </Login>
);

export default SecuredRoutes;
